import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useState } from 'react';
import PDFViewer from '../../components/PDFViewer';

const Table = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const url = '../../assets/docs/ib-proxy-program.pdf';

  const data = [
    {
      field: t('starter'),
      value: [t('non'), '5', '5$', t('multiple')],
    },
    {
      field: t('advanced'),
      value: ['100 LOT', '5', '6$', t('multiple')],
    },
    {
      field: t('expert'),
      value: ['1000 LOT', '5', '7$', t('multiple')],
    },
    {
      field: t('elite'),
      value: ['10,000 LOT', '5', '8$', t('exclusive')],
    },
    {
      field: t('ultimate'),
      value: ['50,000 LOT', '5', '9$', t('exclusive')],
    },
    {
      field: t('legendary'),
      value: ['100,000 LOT', '5', '10$', t('exclusive')],
    },
  ];

  return (
    <div className="overflow-x-auto lg:px-5">
      <div className="my-20 w-[700px] lg:w-full overflow-x-hidden lg:overflow-visible">
        <div className="grid grid-cols-5 h-[65px] items-center border-b border-black dark:border-white gap-2">
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold">
            {t('level')}
          </p>
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold text-center">
            {t('conditional')}
          </p>
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold text-center">
            {t('accounts')}
          </p>
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold text-center">
            {t('comissions')}
          </p>
          <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold text-center">
            {t('agency')}
          </p>
        </div>
        {data.map((field, index) => {
          return (
            <div
              key={index}
              className="grid grid-cols-5 h-[65px] items-center border-b border-black dark:border-white gap-2"
            >
              <p className="text-sm lg:text-2xl text-black dark:text-white custom-font-bold flex justify-between items-center">
                {field.field}
                {/* <img
                  src="/assets/icons/info.svg"
                  alt="info"
                  width={20}
                  height={20}
                /> */}
              </p>
              {field.value.map((value, index) => (
                <p
                  key={index}
                  className="text-sm lg:text-lg text-black dark:text-white text-center"
                >
                  {value}
                </p>
              ))}
            </div>
          );
        })}
        <div className="items-center gap-2 flex mt-5">
          <div className="flex-1">
            <Button
              onClick={() => setShowModal(true)}
              className="!h-[62px] text-sm !lg:text-base w-full flex-1 !bg-green !text-black border-green hover:border-black"
              veryLong
            >
              <div className="flex items-center justify-center gap-3">
                <img
                  src="/assets/icons/file.svg"
                  alt="file"
                  width={24}
                  height={24}
                />
                {t('openProxyFile')}
              </div>
            </Button>
          </div>
          <div className="flex-1">
            <Button
              onClick={() =>
                window.open('https://my.damu.co/partner/register', '_blank')
              }
              className="!h-[62px] text-sm !lg:text-base w-full flex-1"
              veryLong
            >
              {t('openNewAccount2')}
            </Button>
          </div>
        </div>
      </div>
      {showModal && <PDFViewer url={url} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Table;
